import { Injectable } from '@angular/core';

@Injectable()
export class SortingUtil {
  sortData(heading, data) {
    var key = heading.key;
    if (heading.upArrow) {
      data.sort(function (a, b) {
        var nameA = a[key].toString().toLowerCase(),
          nameB = b[key].toString().toLowerCase();
        if (nameA > nameB) return -1
        if (nameA < nameB) return 1
        return 0
      })
    }
    if (heading.downArrow) {
      data.sort(function (a, b) {
        var nameA = a[key].toString().toLowerCase(),
          nameB = b[key].toString().toLowerCase();
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    }
    return data;
  }
}

// date sorting
// employees.sort(function(a, b){
//     var dateA=new Date(a.retiredate), dateB=new Date(b.retiredate)
//     return dateA-dateB //sort by date ascending
// })
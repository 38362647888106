import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../_services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public sidebarOpened = false;
  profile: any = {};
  imageUrl: any = environment.mediaUrl;
  imgUrl: any = 'assets/images/logo.png';
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    } else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    config: NgbDropdownConfig,
    private authService: AuthService
  ) {
    config.placement = 'bottom-right';
  }
  ngOnInit() {
    this.profile = this.authService.getData();
    if (this.profile && this.profile.avatar) {
      this.imgUrl = this.imageUrl + this.profile.avatar;
    }
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}

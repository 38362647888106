import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()

export class CityService {

  constructor(private http: HttpClient) { }

  apiUrl: any = environment.apiUrl;

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  addCity(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/city`, data).pipe(map(this.extractData));
  }
  updateCity(data): Observable<any> {
    return this.http.put(`${this.apiUrl}/v1/employee/city`, data).pipe(map(this.extractData));
  }
  cityList(stateId): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/city?stateId=${stateId}`).pipe(map(this.extractData));
  }
  deleteCity(cityId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/employee/city?cityId=${cityId}`).pipe(map(this.extractData));
  }

 


}

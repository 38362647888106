import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService, AuthService, UserService, StateService, CityService  } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-kisan',
  templateUrl: './kisan.component.html',
  styleUrls: ['./kisan.component.scss']
})
export class KisanComponent implements OnInit {
  myForm: any = {};
  searchForm: any = { search: '' };
  submitted = false;
  loading: boolean = false;
  apiCalled: boolean = false;
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'You want to activate this kisan?';
  popoverInactivateMessage: string = 'You want to deactivate this kisan?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;

  bankFile: any;
  bankImage: any;
  bankImageSelected: boolean = false;

  panCardFile: any;
  panCardImage: any;
  panCardImageSelected: boolean = false;

  aadharFile: any;
  aadharImage: any;
  aadharImageSelected: boolean = false;

  imageUrl: any = environment.mediaUrl;


  title: string;
  pagination: any = {
    docs: [],
    pages: 0,
    total: 0,
    page: 1
  };

  states: any[];
  cityList: any[];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private apiService: EmployeeService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private stateService: StateService,
    private cityService: CityService
  ) {
  }

  ngOnInit() {
     this.fetchKisanList();
     this.fetchStateList();
  }

  fetchStateList(){
    this.stateService.stateList().subscribe(objS => {
       this.states = objS.status == 200 ? objS.data : [];
    });
  }

  fetchCityList(){
    this.cityService.cityList(this.myForm.stateId).subscribe(objS => {
       this.cityList = objS.status == 200 ? objS.data : [];
    });
  }

  fetchKisanList(){
    this.spinner.show();
    this.userService.userList(this.searchForm.search,'Kisan',this.pagination.page).subscribe(objS => {
      this.pagination.docs = objS.data.docs;
      this.pagination.total = objS.data.total 
      this.spinner.hide();
      this.apiCalled = true;
   });
  }

  refreshList(page) {
    this.pagination.page = page ? page : 1;
    this.fetchKisanList();
  }

  openModal(type, data) {
    if (type == 'edit') {
      this.title = 'Edit';
      this.myForm = Object.assign({}, {
        name: data.name,
        phone: data.phone,
        town: data.town,
        tahsil: data.tahsil,
        stateId: data.stateId,
        cityId: data.cityId,
        panCard: data.panCard,
        bankName: data.bankName,
        accountNumber: data.accountNumber,
        ifscCode: data.ifscCode,
        bankAddress: data.bankAddress,
        aadharNumber: data.aadharNumber,
        _id: data._id,
        address: data.address
      });
      this.fetchCityList();

      this.bankImageSelected = true;
      this.panCardImageSelected = true;
      this.aadharImageSelected = true;

      this.bankImage = this.imageUrl + data.accountImage;
      this.panCardImage = this.imageUrl + data.panCardImage;
      this.aadharImage = this.imageUrl + data.aadharImage;

      this.ngxSmartModalService.open('myModal');
    } else {

      this.title = 'Add';

      this.bankImageSelected = false;
      this.panCardImageSelected = false;
      this.aadharImageSelected = false;

      this.bankImage = "";
      this.panCardImage = "";
      this.aadharImage = "";

      this.myForm = { 
        name: '',
        phone: '',
        town: '',
        tahsil: '',
        stateId: '',
        cityId: '',
        panCard: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        bankAddress: '',
        aadharNumber: '',
        address: ''
      };
      this.ngxSmartModalService.open('myModal');
    }
  }

  onSubmit(f) {

    if (this.title == 'Add') {
      this.loading = true;
      // this.myForm.userType = 'Kisan';
      this.myForm.bankFile = this.bankFile;
      this.myForm.panCardFile = this.panCardFile;
      this.myForm.aadharFile = this.aadharFile;

      this.userService.addKisan(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Kisan added");
          this.bankImage = "";
          this.bankImageSelected = false;   
          this.panCardImage = "";
          this.panCardImageSelected = false;
          this.aadharImage = "";
          this.aadharImageSelected = false;
          this.fetchKisanList();
        } else {
          this.toastr.error(objS.userMessage);
        }
        f.submitted = false;
        this.loading = false;
       
      });
    } else {
      this.loading = true;
      this.myForm.bankFile = this.bankFile;
      this.myForm.panCardFile = this.panCardFile;
      this.myForm.aadharFile = this.aadharFile;
      this.userService.updateKisan(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Kisan updated");
          this.bankImage = "";
          this.bankImageSelected = false;   
          this.panCardImage = "";
          this.panCardImageSelected = false;
          this.aadharImage = "";
          this.aadharImageSelected = false;
          this.fetchKisanList();
        } else {
          this.toastr.error(objS.userMessage);
        }
        f.submitted = false;
        this.loading = false;
       
      });
    }
  }


  confirm(kisan) {
    var status = kisan.status == 'Active' ? 'Blocked':'Active';
    this.userService.blockUnblockUser(kisan._id,status).subscribe(objS => {
      this.fetchKisanList();
    });
  }

  cancelled() { }

  cutString(value, wordwise, max, tail) {
    if (!value) return '';
    max = parseInt(max, 10);
    if (!max) return value;
    if (value.length <= max) return value;

    value = value.substr(0, max);
    if (wordwise) {
      var lastspace = value.lastIndexOf(' ');
      if (lastspace !== -1) {
        //Also remove . and , so its gives a cleaner result.
        if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
          lastspace = lastspace - 1;
        }
        value = value.substr(0, lastspace);
      }
    }
    return value + (tail || ' …');
  }


  preview(files,type) {
    console.log(files,type)
    if (files.length === 0) return;
    var mimeType = files[0].type;
    console.log(mimeType);
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Only images are supported");
      return;
    }

    console.log( {type });

    if(type == 'bank'){
      this.bankFile = files[0];
      this.bankImageSelected = true;
    }
    if(type == 'panCard'){
      this.panCardFile = files[0];
      this.panCardImageSelected = true;
    }
    if(type == 'aadharImage'){
      this.aadharFile = files[0];
      this.aadharImageSelected = true;
    }
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if(type == 'bank'){
        this.bankImage = reader.result;
      }
      if(type == 'panCard'){
        this.panCardImage = reader.result;
      }
      if(type == 'aadharImage'){
        this.aadharImage = reader.result;
      }
    }
  }

}

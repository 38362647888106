import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()

export class UserService {
  constructor(private http: HttpClient) { }

  apiUrl: any = environment.apiUrl;
  
  private extractData(res: Response) { return res || {};}

  addUser(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/user`, data).pipe(map(this.extractData));
  }
  updateUser(data): Observable<any> {
    return this.http.put(`${this.apiUrl}/v1/employee/user`, data).pipe(map(this.extractData));
  }
  userList(search,type,page): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/user?search=${search}&type=${type}&page=${page}`).pipe(map(this.extractData));
  }
  blockUnblockUser(userId,status): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/employee/user?userId=${userId}&status=${status}`).pipe(map(this.extractData));
  }
  searchUserList(searchBy): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/user/${searchBy}`).pipe(map(this.extractData));
  }

  traderList(commodityId): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/traderList/${commodityId}`).pipe(map(this.extractData));
  }


  addTrader(data): Observable<any> {
    var fd = new FormData();
    fd.append('name', data.name);
    fd.append('address', data.address);
    fd.append('contactPerson', data.contactPerson);
    fd.append('phone', data.phone);
    fd.append('gstImage', data.gstImage);
    fd.append('gstNumber', data.gstNumber);
    fd.append('comment', data.comment);
    return this.http.post(`${this.apiUrl}/v1/employee/trader`, fd).pipe(map(this.extractData));
  }
  updateTrader(data): Observable<any> {
    var fd = new FormData();
    fd.append('name', data.name);
    fd.append('address', data.address);
    fd.append('contactPerson', data.contactPerson);
    fd.append('phone', data.phone);
    fd.append('gstImage', data.gstImage);
    fd.append('gstNumber', data.gstNumber);
    fd.append('comment', data.comment);
    fd.append('_id', data._id);

    return this.http.put(`${this.apiUrl}/v1/employee/trader`, fd).pipe(map(this.extractData));
  }

  addKisan(data): Observable<any> {
    var fd = new FormData();
    fd.append('name', data.name);
    fd.append('phone', data.phone);
    fd.append('town', data.town);
    fd.append('tahsil', data.tahsil);
    fd.append('stateId', data.stateId);
    fd.append('cityId', data.cityId);
    fd.append('bankName', data.bankName);
    fd.append('address', data.address);

    fd.append('accountNumber', data.accountNumber);
    fd.append('ifscCode', data.ifscCode);
    fd.append('bankAddress', data.bankAddress);
    fd.append('panCard', data.panCard);
    fd.append('aadharNumber', data.aadharNumber);

    fd.append('accountImage', data.bankFile);
    fd.append('panCardImage', data.panCardFile);
    fd.append('aadharImage', data.aadharFile);


    return this.http.post(`${this.apiUrl}/v1/employee/kisan`, fd).pipe(map(this.extractData));
  }
  updateKisan(data): Observable<any> {
    var fd = new FormData();
    fd.append('name', data.name);
    fd.append('phone', data.phone);
    fd.append('town', data.town);
    fd.append('tahsil', data.tahsil);
    fd.append('stateId', data.stateId);
    fd.append('cityId', data.cityId);
    fd.append('bankName', data.bankName);
    fd.append('address', data.address);

    fd.append('accountNumber', data.accountNumber);
    fd.append('ifscCode', data.ifscCode);
    fd.append('bankAddress', data.bankAddress);
    fd.append('panCard', data.panCard);
    fd.append('aadharNumber', data.aadharNumber);

    fd.append('accountImage', data.bankFile);
    fd.append('panCardImage', data.panCardFile);
    fd.append('aadharImage', data.aadharFile);
    
    fd.append('_id', data._id);

    return this.http.put(`${this.apiUrl}/v1/employee/kisan`, fd).pipe(map(this.extractData));
  }

}

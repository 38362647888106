import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService, AuthService, UserService } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  myForm: any = {};
  searchForm: any = { search: '' };
  submitted = false;
  loading: boolean = false;
  apiCalled: boolean = false;
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'You want to activate this employee?';
  popoverInactivateMessage: string = 'You want to deactivate this employee?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;

  title: string;
  pagination: any = {
    docs: [],
    pages: 0,
    total: 0,
    page: 1
  };

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private apiService: EmployeeService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {
    this.fetchEmployeeList();
  }

  fetchEmployeeList() {
    this.spinner.show();
    this.apiService.employeeList(this.searchForm.search,this.pagination.page).subscribe(objS => {
      this.pagination.docs = objS.data.docs;
      this.pagination.total = objS.data.total;
      this.apiCalled = true;
      this.spinner.hide();
    });
  }

  refreshList(page) {
    this.pagination.page = page ? page : 1;
    this.fetchEmployeeList();
  }

  openModal(type, data) {
    if (type == 'edit') {
      this.title = 'Edit';
      this.myForm = Object.assign({}, {
        name: data.name,
        phone: data.phone,
        email: data.email,
        address: data.address,
        employeeId: data._id,
        designation: data.designation
      });
      this.ngxSmartModalService.open('myModal');
    } else {
      this.title = 'Add';
      this.myForm = { designation: "" };
      this.ngxSmartModalService.open('myModal');
    }
  }

  onSubmit(f) {

    if (this.title == 'Add') {
      this.loading = true;
      this.apiService.addEmployee(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Employee added");
          f.submitted = false;
          this.loading = false;
          this.fetchEmployeeList();
        } else {
          this.loading = false;
          this.toastr.error(objS.userMessage);
        }
      });
    } else {
      this.loading = true;
      this.apiService.updateEmployee(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Employee updated");
          f.submitted = false;
          this.loading = false;
          this.fetchEmployeeList();
        } else {
          this.loading = false;
          this.toastr.error(objS.userMessage);
        }
      });
    }
  }


  confirm(emp) {
    var req_data = {
      employeeId: emp._id,
      status: emp.status == 'Active' ? 'Blocked' : 'Active'
    }
    this.apiService.blockUnblockEmployee(req_data).subscribe(objS => {
      this.fetchEmployeeList();
    });
  }
  cancelled() { }

  cutString(value, wordwise, max, tail) {
    if (!value) return '';
    max = parseInt(max, 10);
    if (!max) return value;
    if (value.length <= max) return value;

    value = value.substr(0, max);
    if (wordwise) {
      var lastspace = value.lastIndexOf(' ');
      if (lastspace !== -1) {
        //Also remove . and , so its gives a cleaner result.
        if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
          lastspace = lastspace - 1;
        }
        value = value.substr(0, lastspace);
      }
    }
    return value + (tail || ' …');
  }

}

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, EmployeeService } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  myForm: any = {};
  rollAccess: any = {};
  searchForm: any = { search: '' };
  title: string = '';
  submitted = false;
  loading: boolean = false;
  showError: boolean = false;
  apiCalled: boolean = false;
  dashboardData: any;
  page = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private employeeService : EmployeeService
  ) { }

  ngOnInit() {
    this.employeeService.dashboardData().subscribe(objS => {
      this.dashboardData = objS.data;
    });

    // var request = new XMLHttpRequest();
    // request.open("GET", `${environment.apiUrl}/v1/export`, true);
    // request.responseType = "blob";
    // request.setRequestHeader("Content-type", "application/json");
    // request.onload = function (e) {
    //   if (this.status === 200) {
    //     var file = window.URL.createObjectURL(this.response);
    //     var a = document.createElement("a");
    //     a.href = file;
    //     a.download = 'order.xlsx';
    //     document.body.appendChild(a);
    //     a.click();
    //   }
    // };
    // request.send();
  }
}

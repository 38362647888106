import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService, AuthService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent  {

  constructor() { }

  ngOnInit() {
  }
  // profile$: BehaviorSubject<any>;
  // model: any = {};
  // myForm: any = {};
  // submitted = false;
  // loading: boolean = false;
  // apiCalled: boolean = false;
  // error = false;
  // errorMessage = '';
  // imageUrl: any = environment.mediaUrl;
  // profile: any = {};
  // applicationInfo: any = {};
  // rollAccess: any = {};
  // showError: boolean = false;
  // editable: boolean = false;
  // imgUrl: any = 'assets/images/faces/profile/profile.jpg';
  // message: any = '';
  // imagePath: any = '';
  // authToken = localStorage.getItem('token');
  // constructor(
  //   private route: ActivatedRoute,
  //   private router: Router,
  //   private toastr: ToastrService,
  //   private apiService: EmployeeService,
  //   private authService: AuthService
  // ) { }

  // ngOnInit() {
  //   this.profile = this.authService.getData();
  //   this.apiCalled = false;
  //   this.apiService
  //     .getAppInfo()
  //     .subscribe(res => {
  //       if (res.status == 200) {
  //         this.applicationInfo = res.status == 200 ? res.data : [];
  //         this.apiCalled = true;
  //       } else {
  //         this.toastr.error('Your session has been expired. Please log in again.');
  //         this.authService.logout();
  //         this.router.navigate(['/login']);
  //       }
  //     }, (err) => {
  //       console.log(err);
  //     })
  //   // console.log('this.profile==',this.profile)
  // }

  // editEnable(type) {
  //   if (type == 'edit') {
  //     this.editable = true;
  //   } else {
  //     this.editable = false;
  //     this.ngOnInit();
  //   }
  // }

  // onSubmit(valid) {
  //   this.showError = true;
  //   this.submitted = true;
  //   if (valid) {
  //     // console.log('profile---', this.profile._id)
  //     this.applicationInfo['applicationId'] = this.applicationInfo._id;
  //     this.apiService.updateAppInfo(this.applicationInfo).subscribe(res => {
  //       if (res.status == 200) {
  //         this.ngOnInit();
  //         this.editable = false;
  //         this.toastr.success(res.userMessage);
  //       } else {
  //         this.loading = false;
  //         this.myForm = {};
  //         this.toastr.error(res.userMessage);
  //       }
  //     }, (err) => {
  //       console.log(err);
  //     })
  //   }
  // }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()

export class ProductService {
  constructor(private http: HttpClient) { }
  apiUrl: any = environment.apiUrl;
  private extractData(res: Response) { return res || {};}
  addProduct(data,file): Observable<any> {
    var fd = new FormData();
    fd.append('name', data.name);
    fd.append('price', data.price || '');
    fd.append('veriety', data.veriety || '');
    fd.append('onRentGst', data.onRentGst || '');
    fd.append('onRentHsnCode', data.onRentHsnCode || '');
    fd.append('onSaleGst', data.onSaleGst || '');
    fd.append('onSaleHsnCode', data.onSaleHsnCode || '');
    fd.append('bagCharge', data.bagCharge || 0);
    fd.append('testParameter', data.testParameter);
    fd.append('file', file);
    return this.http.post(`${this.apiUrl}/v1/employee/product`, fd).pipe(map(this.extractData));
  }
  updateProduct(data,file): Observable<any> {
    var fd = new FormData();
    fd.append('name', data.name);
    fd.append('price', data.price);
    fd.append('file', file);
    fd.append('productId', data.productId);
    fd.append('veriety', data.veriety || '');
    fd.append('onRentGst', data.onRentGst || '');
    fd.append('onRentHsnCode', data.onRentHsnCode || '');
    fd.append('onSaleGst', data.onSaleGst || '');
    fd.append('onSaleHsnCode', data.onSaleHsnCode || '');
    fd.append('bagCharge', data.bagCharge  || 0);
    fd.append('testParameter', data.testParameter);
    return this.http.put(`${this.apiUrl}/v1/employee/product`, fd).pipe(map(this.extractData));
  }
  productList(search,page): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/product?search=${search}&page=${page}`).pipe(map(this.extractData));
  }
  deleteProduct(productId,status): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/employee/product?productId=${productId}&status=${status}`).pipe(map(this.extractData));
  }
  list(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/products`).pipe(map(this.extractData));
  }

 
}

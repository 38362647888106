import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
// import { NgDatepickerModule } from 'ng2-datepicker';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxSpinnerService } from "ngx-spinner";
import { ChartsModule } from 'ng2-charts';
// import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
// import for GooglePlaceModule; 
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AuthService,LeadService, UserService, EmployeeService , CityService,ProductService,StateService,WarehouseService, CommodityService} from './_services';
import { BasicAuthInterceptor, AuthGuard, ErrorInterceptor, TimeoutInterceptor, DEFAULT_TIMEOUT } from './_helpers';
import { UserFilterPipe } from './_filters';
import { TwoDigitDecimaNumberDirective, MustMatchDirective, OnlyNumberDirective , NumbersOnlyInputDirective} from './_directives';
import { DateUtil,SortingUtil } from './_utils';


import { FooterComponent } from './components/layout/footer/footer.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { FullComponent } from './components/layout/full/full.component';

import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
// Admin Main Dashbaord //
import { DashboardComponent } from './components/site/dashboard/dashboard.component';

import { Page404Component } from './components/page404/page404.component';
import { BarChartComponent } from './components/site/charts/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './components/site/charts/doughnut-chart/doughnut-chart.component';
import { PieChartComponent } from './components/site/charts/pie-chart/pie-chart.component';
import { SettingComponent } from './components/layout/setting/setting.component';
import { TraderComponent } from './components/site/trader/trader.component';
import { KisanComponent } from './components/site/kisan/kisan.component';
import { EmployeeComponent } from './components/site/employee/employee.component';
import { ProductComponent } from './components/site/product/product.component';
import { LiveCommodityComponent } from './components/site/live-commodity/live-commodity.component';
import { WarehouseComponent } from './components/site/warehouse/warehouse.component';
import { CityComponent } from './components/site/city/city.component';



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    FullComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ProfileComponent,
    DashboardComponent,
    Page404Component,
    TwoDigitDecimaNumberDirective,
    OnlyNumberDirective,
    NumbersOnlyInputDirective,
    MustMatchDirective,
    UserFilterPipe,
    BarChartComponent,
    DoughnutChartComponent,
    PieChartComponent,
    ResetPasswordComponent,
    SettingComponent,
    TraderComponent,
    KisanComponent,
    EmployeeComponent,
    ProductComponent,
    LiveCommodityComponent,
    WarehouseComponent,
    CityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxTrimDirectiveModule,
    ToastrModule.forRoot(),
    // NgDatepickerModule,
    NgxSpinnerModule,
    ChartsModule,
    // NgxMaterialTimepickerModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    // NgMultiSelectDropDownModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    // AngularMultiSelectModule,
    // GooglePlaceModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    LeadService, 
    UserService,
    EmployeeService,
    CityService,
    ProductService,
    StateService,
    WarehouseService,
    CommodityService,
    DateUtil,
    SortingUtil,
    NgxSpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor, multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: TimeoutInterceptor,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 30000
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService, AuthService } from '../../../_services';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {
  myForm: any = {};
  submitted: boolean = false;

  constructor(
    private employeeService: EmployeeService,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
  }




  login() {
    this.submitted = true;
    this.employeeService.login(this.myForm).subscribe(objS => {
      if (objS.status == 200) {
        this.authService.setData(objS.data.employee);
        this.authService.setToken(objS.data.token);
        this.router.navigate(['/dashboard']);
      } else {
        this.toastr.error(objS.userMessage);
      }
      this.submitted = false;

    }, (err) => {
      this.submitted = false;
    });
  }

}

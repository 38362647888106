import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../_services';
import { Observable } from 'rxjs/Observable';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  profile$: Observable<any>;
  profile: Observable<any>;

  public menus = new Array();
  public submenu = new Array();
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    var activeId = localStorage.getItem('ab_active_menu');
    if (activeId) {
      this.navmenuclick(activeId);
    }
    this.profile = this.authService.getData();
  }

  navmenuclick(value) {
    localStorage.setItem('ab_active_menu', value);
    for (let i = 0; i < 7; i++) {
      if (i != value) {
        this.menus[i] = false;
        this.submenu[i + '.' + 0] = false;
        this.submenu[i + '.' + 1] = false;
      }
    }
    if (this.menus[value] == true) {
      this.menus[value] = false;
      localStorage.removeItem('ab_active_menu');
    } else {
      this.menus[value] = true;
    }
  }

}

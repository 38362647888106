import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";


import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,private router: Router,private spinner: NgxSpinnerService,) {}
  intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
        // auto logout if 401 response returned from api
        this.authService.logout();
        this.spinner.hide();
        this.router.navigate(['/login'])
        // location.reload(true);
      }
      const error = err.error.message || err.statusText;
      return next.handle(error);
    }));
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../_services';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        const token = this.authService.getToken();
        if (token) {
          request = request.clone({ setHeaders: { Authorization: `Bearer ${token}`}});
        } else {
          request = request.clone({ setHeaders: { Authorization: 'Basic ' + btoa('agri:agri@12345')}});
        }
        return next.handle(request);;
    }
}

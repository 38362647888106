import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService, AuthService, UserService, CommodityService } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-live-commodity',
  templateUrl: './live-commodity.component.html',
  styleUrls: ['./live-commodity.component.scss']
})
export class LiveCommodityComponent implements OnInit {
  searchForm: any = { search: '' };
  apiCalled: boolean = true;
  pagination: any = {
    docs: [],
    total: 0,
    page: 1,
    limit: 10
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private commodityService: CommodityService
  ) {
  }

  ngOnInit() {
    this.fetchCommodityList();
  }

  fetchCommodityList() {
    this.spinner.show();
    this.searchForm.page = this.pagination.page;
    this.searchForm.type = "Live";
    this.commodityService.commodityList(this.searchForm).subscribe(objS => {
      this.pagination.docs = objS.data.docs;
      this.pagination.total = objS.data.total
      this.spinner.hide();
    });
  }

  refreshList(page) {
    this.pagination.page = page ? page : 1;
    this.fetchCommodityList();
  }

}

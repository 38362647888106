import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService, AuthService } from '../../../_services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  myForm: any = {};
  submitted = false;
  msgError: any = '';
  profile: any;
  isShowPassword = false;
  loading: boolean = false;
  showError: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private employeeService: EmployeeService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.profile = this.authService.getData();
  }
  onSubmit(valid) {
    this.submitted = true;
    this.loading = true;
    this.showError = true;
    if (valid) {
      if (this.myForm.password === this.myForm.newPassword) {
        this.toastr.error('New password should not be same as old password.')
      } else
        if (this.myForm.newPassword === this.myForm.confirmPassword) {
          this.employeeService.changePassword(this.myForm).subscribe(res => {
            if (res.status == 200) {
              this.loading = false;
              this.submitted = false;
              this.showError = false;
              this.myForm = {}
              // this.router.navigate(['/login']);
              this.toastr.success(res.userMessage);
            } else {
              this.toastr.error(res.userMessage);
              this.loading = false;
              this.submitted = false;
              this.showError = false;
            }
          }, (err) => {
            console.log(err);
          })
        } else {
          this.msgError = 'Confirm password should be same as new password'
          this.toastr.error(this.msgError)
        }
    }
  }
  toggleFieldTextType() {
    this.isShowPassword = !this.isShowPassword;
  }

}

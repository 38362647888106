import { Injectable } from '@angular/core';
import { compareAsc, format } from 'date-fns'

@Injectable()
export class DateUtil {

  format_with_time(date) {
    if(!date) return "";
    return format(new Date(date), 'MMM ,dd yyyy hh:mm a').toString(); 
  }
}


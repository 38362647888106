import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService, AuthService, ProductService } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';



@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.5s', style({ opacity: 0 }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s', style({ opacity: 1 }))
          ])
        ], { optional: true })
      ])
    ])
  ],
})
export class ProductComponent implements OnInit {
  myForm: any = {};
  testParameter: any = [{}];
  searchForm: any = { search: '' };
  submitted = false;
  loading: boolean = false;
  apiCalled: boolean = false;
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'Are you sure, you want to activate this product?';
  popoverInactivateMessage: string = 'You want to delete this product?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  imageUrl: any = environment.mediaUrl;
  productImage: any = 'assets/images/product-default.png';
  productFile: any;
  imageSelected: boolean = false;

  title: string;
  pagination: any = {
    docs: [],
    pages: 0,
    total: 0,
    page: 1
  };

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private apiService: EmployeeService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private productService : ProductService
  ) {
  }

  ngOnInit() {
     this.fetchProductList();
  }

  fetchProductList(){
    this.spinner.show();
    this.productService.productList(this.searchForm.search,this.pagination.page).subscribe(objS => {
      this.pagination.docs = objS.data.docs;
      this.pagination.total = objS.data.total;
      this.apiCalled = true;
      this.spinner.hide();
   });
  }

  refreshList(page) {
    this.pagination.page = page ? page : 1;
    this.fetchProductList();
  }

  logAnimation(_event) {
    // console.log(_event)
  }

  openModal(type, data) {
    if (type == 'edit') {
      this.title = 'Edit';
      this.myForm = Object.assign({}, {
        name: data.name,
        price: data.price,
        productId: data._id,
        veriety: data.veriety,
        onRentGst: data.onRentGst,
        onRentHsnCode: data.onRentHsnCode,
        onSaleGst: data.onSaleGst,
        onSaleHsnCode: data.onSaleHsnCode,
        bagCharge: data.bagCharge != 0 ? data.bagCharge : ''
      });
      this.testParameter = data.testParameter;
      this.imageSelected = true;
      this.productFile = "";
      this.productImage = this.imageUrl + data.image;

      this.ngxSmartModalService.open('myModal');
    } else {
      this.title = 'Add';
      this.imageSelected = false;
      this.productFile = "";
      this.myForm = { };
      this.testParameter = [{}];
      this.ngxSmartModalService.open('myModal');
    }
  }

 
  onSubmit(f) {
    if (this.title == 'Add') {
      this.myForm.testParameter = JSON.stringify(this.testParameter);
      this.productService.addProduct(this.myForm,this.productFile).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Warehouse added");
          f.submitted = false;
          this.imageSelected = false;
          this.productFile = "";
          this.fetchProductList();
        } else {
          this.toastr.error(objS.userMessage);
        }
      });
    } else {
      this.myForm.testParameter = JSON.stringify(this.testParameter);
      this.productService.updateProduct(this.myForm,this.productFile).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Product updated");
          f.submitted = false;
          this.imageSelected = false;
          this.productFile = "";
          this.fetchProductList();
        } else {
          this.toastr.error(objS.userMessage);
        }
      });
    }
  }



  confirm(product) {
    this.productService.deleteProduct(product._id,'Deleted').subscribe(objS => {
      this.toastr.success("Product deleted.");
      this.fetchProductList();
    });
  }
  cancelled() { }


  preview(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Only images are supported");
      return;
    }
    var reader = new FileReader();
    this.productFile = files[0];
    this.imageSelected = true;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.productImage = reader.result;
    }
  }

   //@ add new row
   addRow() {
    this.testParameter.push({
      "name": "",
      "testMethod": "",
      "unit": ""
    })
  }

  //@ remove last row
  removeLastRow(){
     this.testParameter.pop();
  }

}

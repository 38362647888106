import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

  constructor() { }

  setData(data: any) {
    localStorage.setItem('ab_data', JSON.stringify(data));
  }

  setToken(token: string) {
    localStorage.setItem('ab_token', token);
  }

  getData() {
    return JSON.parse(localStorage.getItem('ab_data'));
  }


  getToken() {
   return localStorage.getItem('ab_token');
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }


  logout() {
    localStorage.removeItem('ab_data');
    localStorage.removeItem('ab_token');
  }
}

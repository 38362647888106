import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()

export class EmployeeService {

  constructor(private http: HttpClient) { }

  apiUrl: any = environment.apiUrl;

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
  login(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/login`, data).pipe(map(this.extractData));
  }

  changePassword(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/changePassword`, data).pipe(map(this.extractData));
  }

  dashboardData(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/dashboard`).pipe(map(this.extractData));
  }
  // addSubadmin(data): Observable<any> {
  //   return this.http.post(`${this.apiUrl}/admin/subadmin`, data).pipe(map(this.extractData));
  // }

  // updateSubadmin(data): Observable<any> {
  //   return this.http.put(`${this.apiUrl}/admin/subadmin`, data).pipe(map(this.extractData));
  // }

  // blockUnblockAdmin(adminId): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/admin/subadmin?adminId=` + adminId).pipe(map(this.extractData));
  // }

  // adminList(data): Observable<any> {
  //   return this.http.get(`${this.apiUrl}/admin/subadmin?page=` + data.page + '&&searchBy=' + data.searchBy).pipe(map(this.extractData));
  // }

 

  updateProfile(data): Observable<any> {
    var fd = new FormData();
    fd.append('_id', data._id);
    fd.append('email', data.email);
    fd.append('name', data.name);
    fd.append('phone', data.phone);
    fd.append('file', data.file);
    return this.http.post(`${this.apiUrl}/admin/profile`, fd).pipe(map(this.extractData));
  }

  // getAppInfo(): Observable<any> {
  //   return this.http.get(`${this.apiUrl}/admin/application`).pipe(map(this.extractData));
  // }

  // updateAppInfo(data): Observable<any> {
  //   return this.http.post(`${this.apiUrl}/admin/application`, data).pipe(map(this.extractData));
  // }


  // getcityList(data): Observable<any> {
  //   return this.http.get(`${this.apiUrl}/admin/city?page=` + data.page + '&&searchBy=' + data.searchBy).pipe(map(this.extractData));
  // }


  // getcityListForDropdown(data): Observable<any> {
  //   return this.http.get(`${this.apiUrl}/admin/citydropdown?searchBy=` + data.searchBy).pipe(map(this.extractData));
  // }

  // addCity(data): Observable<any> {
  //   return this.http.post(`${this.apiUrl}/admin/city`, data).pipe(map(this.extractData));
  // }

  // updateCity(data): Observable<any> {
  //   return this.http.put(`${this.apiUrl}/admin/city`, data).pipe(map(this.extractData));
  // }

  // blockUnblockCity(cityId): Observable<any> {
  //   return this.http.delete(`${this.apiUrl}/admin/city?cityId=` + cityId).pipe(map(this.extractData));
  // }

  addEmployee(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/add`, data).pipe(map(this.extractData));
  }
  updateEmployee(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/update`, data).pipe(map(this.extractData));
  }
  employeeList(search,page): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/list?search=${search}&page=${page}`).pipe(map(this.extractData));
  }
  blockUnblockEmployee(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/updateStatus`,data).pipe(map(this.extractData));
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService, AuthService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  model: any = {};
  myForm: any = {};
  submitted = false;
  loading: boolean = false;
  error = false;
  errorMessage = '';
  imageUrl: any = environment.mediaUrl;
  profile: any ;
  rollAccess: any = {};
  showError: boolean = false;
  editable: boolean = false;
  imgUrl: any = 'assets/images/logo.png';
  message: any = '';
  imagePath: any = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private apiService: EmployeeService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.profile = this.authService.getData();
    // this.imgUrl = this.imageUrl + this.profile.avatar;
    // console.log('this.profile==',this.profile)
  }

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    this.myForm.file = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgUrl = reader.result;
    }
  }

  editEnable(type) {
    if (type == 'edit') {
      this.editable = true;
    } else {
      this.editable = false;
      this.ngOnInit();
    }
  }

  onSubmit(valid) {
    return;
    this.showError = true;
    this.submitted = true;
    if (valid) {
      this.myForm = {
        name: this.profile.name,
        email: this.profile.email,
        phone: this.profile.phone,
        _id: this.profile._id,
        file: this.myForm.file
      }
      // console.log('profile---', this.profile._id)
      this.apiService.updateProfile(this.myForm).subscribe(res => {
        if (res.status == 200) {
          res.data['authToken'] = this.profile.authToken;
          res.data['type'] = this.profile.type;
          res.data['rollAccess'] = this.profile.rollAccess;
          this.authService.setData(res.data);
          this.loading = false;
          this.myForm = {};
          this.editable = false;
          this.toastr.success('Profile updated successfully.');
        } else {
          this.loading = false;
          this.myForm = {};
          this.toastr.error(res.userMessage);
        }
      }, (err) => {
        console.log(err);
      })
    }
  }
}

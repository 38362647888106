import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService, AuthService , StateService,CityService , WarehouseService} from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {
  myForm: any = {};
  searchForm: any = { search: '' };
  submitted = false;
  loading: boolean = false;
  apiCalled: boolean = false;
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverDeleteMessage: string = 'You want to delete this warehouse?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;

  title: string;
  states: any[];
  cityList: any[];
  pagination: any = {
    docs: [],
    pages: 0,
    total: 0,
    page: 1
  };

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private apiService: EmployeeService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private stateService: StateService,
    private cityService: CityService,
    private warehouseService : WarehouseService
  ) {
  }

  ngOnInit() {
     this.fetchWarehouseList();
     this.fetchStateList();
  }

  fetchStateList(){
    this.stateService.stateList().subscribe(objS => {
       this.states = objS.status == 200 ? objS.data : [];
    });
  }

  fetchCityList(){
    this.cityService.cityList(this.myForm.stateId).subscribe(objS => {
       this.cityList = objS.status == 200 ? objS.data : [];
    });
  }

  fetchWarehouseList(){
    this.spinner.show();
    this.warehouseService.warehouseList(this.searchForm.search).subscribe(objS => {
      this.pagination.docs = objS.data.docs;
      this.pagination.total = objS.data.total;
      this.apiCalled = true;
      this.spinner.hide();
   });
  }

  refreshList(page) {
    this.pagination.page = page ? page : 1;
    this.fetchWarehouseList();
  }

  openModal(type, data) {
    if (type == 'edit') {
      this.title = 'Edit';
      this.myForm = Object.assign({}, {
        warehouseId: data._id,
        name: data.name,
        address: data.address,
        licence: data.licence,
        expiryDate: data.expiryDate,
        stateId: data.stateId._id,
        cityId: data.cityId._id
      });
      this.fetchCityList();
      this.ngxSmartModalService.open('myModal');
    } else {
      this.title = 'Add';
      this.myForm = { stateId: '', cityId: ''};
      this.ngxSmartModalService.open('myModal');
    }
  }

 
  onSubmit(f) {
    if (this.title == 'Add') {
      this.warehouseService.addWarehouse(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Warehouse added");
          f.submitted = false;
          this.fetchWarehouseList();
        } else {
          this.toastr.error(objS.userMessage);
        }
      });
    } else {
      this.warehouseService.updateWarehouse(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Warehouse updated");
          f.submitted = false;
          this.fetchWarehouseList();
        } else {
          this.toastr.error(objS.userMessage);
        }
      });
    }
  }



  confirm(warehouse) {
    this.warehouseService.deleteWarehouse(warehouse._id).subscribe(objS => {
      this.toastr.success("Warehouse deleted.");
      this.fetchWarehouseList();
    });
  }
  cancelled() { }

}

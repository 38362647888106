import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';

import { LoginComponent } from './components/auth/login/login.component';
import { Page404Component } from './components/page404/page404.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { SettingComponent } from './components/layout/setting/setting.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { DashboardComponent } from './components/site/dashboard/dashboard.component';

import { TraderComponent } from './components/site/trader/trader.component';
import { KisanComponent } from './components/site/kisan/kisan.component';
import { EmployeeComponent } from './components/site/employee/employee.component';
import { ProductComponent } from './components/site/product/product.component';
import { LiveCommodityComponent } from './components/site/live-commodity/live-commodity.component';
import { WarehouseComponent } from './components/site/warehouse/warehouse.component';
import { CityComponent } from './components/site/city/city.component';
import { FullComponent } from './components/layout/full/full.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: 'LOGIN' } },
  { path: 'forgotPassword', component: ForgotPasswordComponent, data: { title: 'FORGOT PASSWORD' } },
  { path: 'change_password', component: ChangePasswordComponent, data: { title: 'CHANGE PASSWORD' }, canActivate: [AuthGuard] },
  { path: 'reset_password', component: ResetPasswordComponent, data: { title: 'RESET PASSWORD' } },
  { path: 'profile', component: ProfileComponent, data: { title: 'PROFILE' }, canActivate: [AuthGuard] },
  { path: 'setting', component: SettingComponent, data: { title: 'PROFILE' }, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, data: { title: 'DASHBOARD' }, canActivate: [AuthGuard] },
  { path: 'trader', component: TraderComponent, data: { title: 'Trader' }, canActivate: [AuthGuard] },
  { path: 'kisan', component: KisanComponent, data: { title: 'Kisan' }, canActivate: [AuthGuard] },
  { path: 'employee', component: EmployeeComponent, data: { title: 'Employee' }, canActivate: [AuthGuard] },
  { path: 'product', component: ProductComponent, data: { title: 'Product' }, canActivate: [AuthGuard] },
  
  { path: 'live-commodity', component: LiveCommodityComponent, data: { title: 'Live Commodity' }, canActivate: [AuthGuard] },
  { path: 'warehouse', component: WarehouseComponent, data: { title: 'Warehouse' }, canActivate: [AuthGuard] },
  { path: 'city', component: CityComponent, data: { title: 'City' }, canActivate: [AuthGuard] },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'lead',
        loadChildren: () => import('./components/site/lead/lead.module').then(m => m.LeadModule)
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'commodity',
        loadChildren: () => import('./components/site/commodity/commodity.module').then(m => m.CommodityModule)
      }
    ]
  },

  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'e-auction',
        loadChildren: () => import('./components/site/eAuction/eauction.module').then(m => m.EAuctionModule)
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'account',
        loadChildren: () => import('./components/site/account/account.module').then(m => m.AccountModule)
      }
    ]
  },
   
  { path: '**', pathMatch: 'full', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()

export class StateService {

  constructor(private http: HttpClient) { }

  apiUrl: any = environment.apiUrl;

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
  
  stateList(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/state`).pipe(map(this.extractData));
  }

}

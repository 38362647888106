import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()

export class WarehouseService {

  constructor(private http: HttpClient) { }

  apiUrl: any = environment.apiUrl;

  private extractData(res: Response) { return res || {};}

  addWarehouse(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/warehouse`, data).pipe(map(this.extractData));
  }
  updateWarehouse(data): Observable<any> {
    return this.http.put(`${this.apiUrl}/v1/employee/warehouse`, data).pipe(map(this.extractData));
  }
  warehouseList(search): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/warehouse?search=${search}`).pipe(map(this.extractData));
  }
  deleteWarehouse(warehouseId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/employee/warehouse?warehouseId=${warehouseId}`).pipe(map(this.extractData));
  }

  unitList(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/unit`).pipe(map(this.extractData));
  }

  list(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/warehouses`).pipe(map(this.extractData));
  }
 
}

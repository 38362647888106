import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService, AuthService, StateService, CityService } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit {
  myForm: any = {};
  searchForm: any = { search: '', stateId: '' };
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverDeleteMessage: string = 'You want to delete this city?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  cityAdding: boolean = false;
  cityUpdating: boolean = false;
  apiCalled: boolean = false;


  title: string;
  states: any[];
  cityList: any[] = [];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private apiService: EmployeeService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private stateService: StateService,
    private cityService: CityService
  ) {
  }

  ngOnInit() {
    this.fetchStateList();
  }

  fetchStateList() {
    this.stateService.stateList().subscribe(objS => {
      this.states = objS.status == 200 ? objS.data : [];
    });
  }

  fetchCityList() {
    if (this.searchForm.stateId) {
      this.spinner.show();
      this.cityService.cityList(this.searchForm.stateId).subscribe(objS => {
        this.cityList = objS.data;
        this.apiCalled = true;
        this.spinner.hide();
      });
    }
  }

  refreshList() {
    this.fetchCityList();
  }

  openModal(type, data) {
    if (type == 'edit') {
      this.title = 'Edit';
      this.myForm = Object.assign({}, {
        cityId: data._id,
        city: data.city,
        stateId: data.stateId
      });
      this.ngxSmartModalService.open('myModal');
    } else {
      this.title = 'Add';
      this.myForm = { stateId: "" };
      this.ngxSmartModalService.open('myModal');
    }
  }

  onSubmit(f) {
    if (this.title == 'Add') {
      this.cityService.addCity(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("City added");
          f.submitted = false;
          this.fetchCityList();
        } else {
          this.toastr.error(objS.userMessage);
        }
      });
    } else {
      this.cityService.updateCity(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("City updated");
          f.submitted = false;
          this.fetchCityList();
        } else {
          this.toastr.error(objS.userMessage);
        }
      });
    }
  }


  confirm(city) {
    this.cityService.deleteCity(city._id).subscribe(objS => {
      this.toastr.success("City deleted.");
      this.fetchCityList();
    });

  }
  cancelled() { }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()

export class CommodityService {

  constructor(private http: HttpClient) { }

  apiUrl: any = environment.apiUrl;

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
  
  addCommodity(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/commodity`,data).pipe(map(this.extractData));
  }

  commodityList(data): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/commodity?page=${data.page}&search=${data.search}&type=${data.type}`).pipe(map(this.extractData));
  }

  accountCommodityList(data): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/account/commodity?page=${data.page}&search=${data.search}&status=${data.status}`).pipe(map(this.extractData));
  }

  releaseCommodity(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/release/commodity`,data).pipe(map(this.extractData));
  }
  updatePayment(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/commodity/payment`,data).pipe(map(this.extractData));
  }

  commodityDetail(id): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/commodity/${id}`).pipe(map(this.extractData));
  }

  commodity_list(data): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/commodityList?page=${data.page}&search=${data.search}&live=${data.live}`).pipe(map(this.extractData));
  }

  publishCommodity(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/publishCommodity`,data).pipe(map(this.extractData));
  }

  unPublishCommodity(commodityId): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/unpublishCommodity/${commodityId}`).pipe(map(this.extractData));
  }

  generateCommodityLiveOtp(commodityId): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/employee/generateCommodityLiveOtp/${commodityId}`).pipe(map(this.extractData));
  }

  addCommodityLiveRequest(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/commodity/addLiveRequest`,data).pipe(map(this.extractData));
  }

  blockCommodity(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/commodity/block`,data).pipe(map(this.extractData));
  }
  saveCharges(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/employee/commodity/updateCharge`,data).pipe(map(this.extractData));
  }

}

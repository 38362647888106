import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService, AuthService, UserService } from '../../../_services';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-trader',
  templateUrl: './trader.component.html',
  styleUrls: ['./trader.component.scss']
})

export class TraderComponent implements OnInit {
  myForm: any = {};
  searchForm: any = { search: '' };
  submitted = false;
  loading: boolean = false;
  apiCalled: boolean = false;
  placements: string[] = ['top', 'left', 'right', 'bottom'];
  popoverTitle: string = 'Are you sure?';
  popoverActivateMessage: string = 'You want to activate this trader?';
  popoverInactivateMessage: string = 'You want to deactivate this trader?';
  confirmText: string = 'Yes <i class="fa fa-check" (click)="confirm()"></i>';
  cancelText: string = 'No <i class="fa fa-times"></i>';
  confirmClicked: boolean = false;
  cancelClicked: boolean = false;
  
  gstImageSelected: boolean = false;
  gstImage: any;
  file: any;
  imageUrl: any = environment.mediaUrl;

  title: string;
  pagination: any = {
    docs: [],
    pages: 0,
    total: 0,
    page: 1
  };

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private apiService: EmployeeService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
     this.fetchTraderList();
  }

  fetchTraderList(){
    this.spinner.show();
    this.userService.userList(this.searchForm.search,'Trader',this.pagination.page).subscribe(objS => {
      this.pagination.docs = objS.data.docs;
      this.pagination.total = objS.data.total
      this.spinner.hide();
      this.apiCalled = true;
   });
  }

  refreshList(page) {
    this.pagination.page = page ? page : 1;
    this.fetchTraderList();
  }

  openModal(type, data) {
    if (type == 'edit') {
      this.title = 'Edit';
      this.myForm = Object.assign({}, {
        name: data.name,
        phone: data.phone,
        address: data.address,
        contactPerson: data.contactPerson,
        gstNumber: data.gstNumber,
        comment: data.comment,
        _id: data._id
      });
      this.gstImageSelected = true;
      this.gstImage = this.imageUrl + data.gstImage,

      this.ngxSmartModalService.open('myModal');
    } else {
      this.title = 'Add';
      this.myForm = {
        name : '',
        phone : '',
        address : '',
        contactPerson : '',
        gstNumber : '',
        comment : '',
      };
      this.ngxSmartModalService.open('myModal');
    }
  }

  onSubmit(f) {

    if (this.title == 'Add') {
      this.loading = true;
      // this.myForm.userType = 'Trader';
      this.myForm.gstImage = this.file;
      this.userService.addTrader(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Trader added");
          f.submitted = false;
          this.loading = false;
          this.gstImageSelected = false;
          this.gstImage = "";
          this.file = "";
          this.fetchTraderList();
        } else {
          this.loading = false;
          this.toastr.error(objS.userMessage);
        }
      });
    } else {
      this.loading = true;
      this.myForm.gstImage = this.file;

      this.userService.updateTrader(this.myForm).subscribe(objS => {
        if (objS.status == 200) {
          this.ngxSmartModalService.close('myModal');
          this.toastr.success("Trader updated");
          f.submitted = false;
          this.loading = false;
          this.gstImageSelected = false;
          this.gstImage = "";
          this.file = "";
          this.fetchTraderList();
        } else {
          this.loading = false;
          this.toastr.error(objS.userMessage);
        }
      });
    }
  }


  confirm(trader) {
    var status = trader.status == 'Active' ? 'Blocked':'Active';
    this.userService.blockUnblockUser(trader._id,status).subscribe(objS => {
      this.fetchTraderList();
    });
  }
  cancelled() { }

  cutString(value, wordwise, max, tail) {
    if (!value) return '';
    max = parseInt(max, 10);
    if (!max) return value;
    if (value.length <= max) return value;

    value = value.substr(0, max);
    if (wordwise) {
      var lastspace = value.lastIndexOf(' ');
      if (lastspace !== -1) {
        //Also remove . and , so its gives a cleaner result.
        if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
          lastspace = lastspace - 1;
        }
        value = value.substr(0, lastspace);
      }
    }
    return value + (tail || ' …');
  }
  preview(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastr.error("Only images are supported");
      return;
    }
    var reader = new FileReader();
    this.file = files[0];
    this.gstImageSelected = true;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.gstImage = reader.result;
    }
  }

}
